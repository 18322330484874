import React, { PureComponent } from "react"
import Layout from '../components/layout';
import LearnMore from '../components/learn-more';

export default class LearnMorePage extends PureComponent {
  render() {
    return (
      <Layout>
        <LearnMore/>
      </Layout>
    )
  }
}

