import React, { Fragment, PureComponent } from 'react';
import { Link } from "gatsby"
import { Row } from 'react-bootstrap';
import RaisedButton from './rasied-button';
import ServicesToni from '../images/services-toni.png';
import DigitalCourses from '../images/digital-courses.png';
import HighLevelCoachingService from '../images/high-level-coaching-service.png';
import GroupCoaching from '../images/group-coaching.png';
import WellnessPresentations from '../images/wellness-presentations.png'
import PrivateCoaching from '../images/private-coaching.png';
import CustomWellnessPrograms from '../images/customized-wellness-programs.png';


const ServiceSection = ({alt, img, imgAlt, href, title, rightAligned = false, children }) => {
  const imgContent = (
    <div className='service-section-img'>
      {
      href ? 
      <Link to={href}>
        <img src={img} alt={imgAlt || title} />
      </Link> : 
      <img src={img} alt={imgAlt || title} />
      }
    </div>
  );
  const infoContent = (
      <div className='service-section-info'>
        {
        href ? 
        <Link to={href}>
          <h2>{title}</h2>
        </Link> :
        <h2>{title}</h2>
        }
        {children}
      </div>
  )
  return (
    <section className={`service-section ${rightAligned ? 'service-section-right' : 'service-section-left'}${alt ? ' alt-service' : ''}`}>
      {rightAligned ? (
        <Fragment>
          {infoContent}
          {imgContent}
        </Fragment>
      ) : (
        <Fragment>
          {imgContent}
          {infoContent}
        </Fragment>
      )}
    </section>
  );
};

export default class LearnMore extends PureComponent {
  render() {
    const { stretchRowStyles } = this.props;
    const portrait = stretchRowStyles != null &&  stretchRowStyles.width <= 768;
    return (
      <div className='services'>
        <Row className='intro-banner' style={{
          ...stretchRowStyles
        }}>
          <div className='intro-banner-content'>
            <p className='banner-header'>
              Could This Be The Start Of Something New For You?
            </p>
            <p>
              So, you want to be able to live a fulfilling life at work and at home, right? You want to feel passionate about what you do, without exhausting yourself. At the same time, you want to be able to enjoy quality time with loved ones and maybe even have some time to yourself. But, how?
            </p>
            <p className='banner-bold'>
              You don’t have to sacrifice your impact, your drive, your health, or those who matter most to you, in order to gain some semblance of balance in life.
            </p>
            <p>
              Believe it or not, you can enhance your happiness, health and performance at work, home or both, with much less stress and much more ease.
            </p>
            <p>
              When looking for a coach, you don’t have to choose between someone who either has the professional qualifications or the personal experience. Dr. Toni brings both to the table. Offering a unique combination of being a down to earth mentor, and a challenging coach; your growth through working together is inevitable.
            </p>
            <p>
              With over a decade of experience in the field of human behavior, Dr. Toni incorporates current research, practical strategies, and powerful mindset techniques into her work with clients.
            </p>
          </div>
          <div className='intro-banner-picture'>
            <img src={ServicesToni} alt={'Dr. Toni Warner-McIntyre'} style={{ height: '100%', width: '100%' }} />
          </div>
        </Row>
        <Row className='services-cta'>
          <h2>Ready to make a change?</h2>
          <div>
            <RaisedButton href='https://forms.gle/jnZ9SwYi61qVF1Z39'>
              Click Here To Schedule A Consultation Now!
            </RaisedButton>
          </div>
          <div className='or'>
              or take a look at the offerings below:
          </div>
        </Row>
        <Row className='offerings-row individual-offerings'>
          <h1>Private Coaching {'&'} Courses</h1>
          <ServiceSection href={'/coaching'} img={PrivateCoaching} title='One on One Coaching with Dr. Toni'>
            <p>
              High-touch, 1:1 coaching puts Dr. Toni in your back pocket.
            </p>
            <p>
              The most comprehensive and individualized package available, Dr. Toni will provide the personalized experience that maximizes your desired results!
            </p>
            <p>
              Direct coaching sessions, e-materials, in-between session support, and 24/7 access to her signature Balance Blueprint program; this opportunity is limited in availability and is accessible by application only.
            </p>
          </ServiceSection>
          <ServiceSection href={'https://courses.boldandbalancedcoaching.com'} alt rightAligned={!portrait} img={DigitalCourses} title='Self-paced Digital Coaching Courses'>
            <p>
              A variety of self-paced digital coaching courses to teach leaders how to navigate common areas of challenge, like time management, high stress levels and motivation.
            </p>
            <p>
              Access these affordable courses quickly and conveniently, with the touch of a button.
            </p>
            <p>
              All courses are hand-crafted by Dr. Toni and exclusive to Dr. Toni coaches; they can’t be found anywhere else. 
            </p>
            <p>
              Click here or visit the Resources Tab to see what digital courses are currently available.
            </p>
          </ServiceSection>
          <ServiceSection href={'https://courses.boldandbalancedcoaching.com/products/courses/view/39'} img={GroupCoaching} title='Live Group Coaching'>
            <p>
              The RESET is currently being offered as a live group coaching course.
            </p>
            <p>
              Select availability throughout the year. You can sign up to join the waitlist and find out when the next group coaching opportunity opens for enrollment.
            </p>
          </ServiceSection>
        </Row>
        <Row className='services-cta'>
          <h2>Don't wait to burn out!</h2>
          <div>
            <RaisedButton href='https://forms.gle/jnZ9SwYi61qVF1Z39'>
              Schedule A Consultation To Tackle Burn Out Now!
            </RaisedButton>
          </div>
          <div className='or'>
            or if you're interested in help for your business...
          </div>
        </Row>
        <Row className='offerings-row business-offerings'>
          <h1>For Businesses</h1>
          <ServiceSection href='https://forms.gle/jnZ9SwYi61qVF1Z39' alt rightAligned={!portrait} img={WellnessPresentations} title='Interactive Wellness Presentations'>
            <p>
              Want an engaging presentation aimed to reduce burnout and increase staff productivity, all while leaving you and your team armed with practical tools and knowledge to benefit from, for decades to come?
            </p>
            <p>
              CURRENTLY AVAILABLE PRESENTATIONS
            </p>
            <ul>
              <li>
                The Business {'&'} Balance Presentation. Your team will learn how to recognize  and effectively address burnout through this interactive presentation, accompanied by practical tips and tools that further aid in over-all stress reduction.
              </li>
              <li>
                The RESET. This step-by-step presentation will walk your team through the 8-step RESET process designed to effectively guide individuals to up-level in or get unstuck from a targeted area of their life. Personal wellness amplifies professional wellness, and this interactive presentation offers the opportunity to enhance both!
              </li>
              <li>
                Customized Presentations. Have another topic you’d like Dr. Toni to present on? Share what you’re looking for here, and Dr. Toni will get back to you with a customized proposal for a quality, impactful presentation that suits the needs of your team. 
              </li>
            </ul>
          </ServiceSection>
          <ServiceSection href='https://forms.gle/jnZ9SwYi61qVF1Z39' img={CustomWellnessPrograms} title='Customized Wellness Programs'>
            <p>
              Have a wellness course created to meet the needs of your team, with research and experience backed content, powerful coaching lessons and convenient virtual access for your team members to tap into on their time and on their terms.
            </p>
          </ServiceSection>
          <ServiceSection href='https://forms.gle/jnZ9SwYi61qVF1Z39' alt rightAligned={!portrait} img={HighLevelCoachingService} title='High Level Leadership Coaching Package'>
            <p>
              The most impactful way to bolster your teams performance, productivity and satisfaction at work, is to also provide high-touch coaching to yourself and your leadership. 
            </p>
            <p>
              This premium level package is highly customizable and is aimed at supporting your business at all levels. Your package may include a combination of any or all of the above, including 1:1 coaching for select leaders in your organization as well.
            </p>
          </ServiceSection>
        </Row>
        <Row className='services-cta'>
          <h2>Balance doesn't have to wait!</h2>
          <div>
            <RaisedButton href='https://forms.gle/jnZ9SwYi61qVF1Z39'>
              Schedule A Consultation Today!
            </RaisedButton>
          </div>
          <div className='or'>
            You'll be glad you did.
          </div>
        </Row>
      </div>
    )
  }
}
